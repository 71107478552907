<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <div class="mb-2 text-right">
                <button
                    class="btn waves-effect waves-light"
                    :class="
                        `btn-${ onlyConsistentStatus ? 'success' : 'danger' }`
                    "
                    @click="onConsistentStatusChange"
                >
                    Consistent Statuses {{ onlyConsistentStatus ? 'Enabled' : 'Disabled' }}
                    <span class="btn-label-right">
                        <i v-if="onlyConsistentStatus" class="mdi mdi-check-all" />
                        <i v-else class="mdi mdi-cancel" />
                    </span>
                </button>
            </div>

            <b-alert
                :show="!!fedexData.shipmentsTotal"
                dismissible
                variant="success"
                @dismissed="fedexData.shipmentsTotal = 0"
            >
                <span>
                    <b>{{ fedexData.shipments.length }} of {{ fedexData.shipmentsTotal }}</b>
                    FedEx shipments were successfully created!
                </span>
            </b-alert>

            <b-alert
                :show="!!fedexData.errors.length"
                dismissible
                variant="danger"
                @dismissed="fedexData.errors = []"
            >
                <span>Errors occured during creation of FedEx shipments:</span>
                <div class="table-responsive table-alert">
                    <table class="table mt-3 table-centered table-sm">
                        <thead>
                            <tr>
                                <th>Order Number</th>
                                <th>Code</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="fedexError in fedexData.errors">
                                <tr
                                    v-for="(error, index) in fedexError.errors"
                                    :key="`${fedexError.orderNumber}_${index}`"
                                >
                                    <td>{{ fedexError.orderNumber }}</td>
                                    <td>{{ error.code }}</td>
                                    <td>{{ error.message }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </b-alert>

            <awesome-table
                id="factory-data"
                :key="users.length"
                :labels="labels"
                :items="orderProductColors"
                :options="{
                    addButton: false,
                    sort: true,
                    select: true,
                    filters: true,
                    columnsSelector: true,
                    csvExport: false,
                    isCard: false,
                    tableStyle: 'font-size: 12px;'
                }"
                :pagination="pagination"
                :is-loading="isLoading"
                :filters="filters"
                :get-row-class="getRowClass"
                @row-click="$router.push(`/factory-data/${$event.id}`)"
                @mouse-wheel-click="$openNewTab(`/factory-data/${$event.id}`)"
                @pagination-change="onPaginationChange"
                @selection-change="onSelectionChange"
                @filter-change="onFilterChange"
            >
                <template #selectButtons>
                    <button
                        class="btn btn-primary waves-effect waves-light mr-1"
                        type="button"
                        @click="printInstructions"
                    >
                        <span class="btn-label">
                            <i class="mdi mdi-printer" />
                        </span>
                        Print Instructions
                    </button>
                    <button
                        class="btn btn-blue waves-effect waves-light mr-1"
                        type="button"
                        :disabled="shipmentInProgress || batchInProgress"
                        @click="onCreateShipment"
                    >
                        <span class="btn-label">
                            <b-spinner v-if="shipmentInProgress" small></b-spinner>
                            <i v-else class="fab fa-fedex" />
                        </span>
                        Create Shipment
                    </button>
                    <button
                        class="btn btn-blue waves-effect waves-light mr-1"
                        type="button"
                        :disabled="shipmentInProgress || batchInProgress"
                        @click="onCreateBatch"
                    >
                        <span class="btn-label">
                            <b-spinner v-if="batchInProgress" small></b-spinner>
                            <i v-else class="mdi mdi-alpha-b-box" />
                        </span>
                        Create Batch
                    </button>
                    <button
                        class="btn btn-info waves-effect waves-light mr-1"
                        type="button"
                        @click="copyTrackingNumbersToClipboard"
                    >
                        <span class="btn-label">
                            <i class="far fa-copy" />
                        </span>
                        Copy Tracking
                    </button>
                </template>
                <template #[`items.order.order_number`]="{ item }">
                    {{ item.order.order_number || item.order.magento_order_number }}
                </template>
                <template #[`items.assignee`]="{ item }">
                    <span v-if="item.assignee">
                        {{
                            `${item.assignee.first_name}
                            ${item.assignee.last_name}`
                        }}
                    </span>
                </template>
                <template #[`items.priority`]="{ value }">
                    <span :class="getBadgeClass(value)">
                        {{ value | startCase }}
                    </span>
                </template>
                <template #[`items.order.customer.first_name`]="{ item }">
                    {{
                        `${item.order.customer.first_name}
                        ${item.order.customer.last_name}`
                    }}
                </template>
                <template #[`items.account.name`]="{ item }">
                    <span v-if="item.account.name">
                        {{ item.account.name }}
                    </span>
                    <span v-else>
                        {{
                            `${item.order.customer.first_name}
                            ${item.order.customer.last_name}`
                        }}
                    </span>
                </template>
                <template #[`items.scan_link`]="{ item }">
                    <a
                        v-if="item.scan_link"
                        :href="item.scan_link"
                        target="_blank"
                        class="btn btn-xs btn-blue"
                        @click.stop
                    >
                        3D Scan
                    </a>
                </template>
                <template #[`items.instructions_file`]="{ item }">
                    <a
                        v-if="item.instruction"
                        :href="`${appUrl}/factory-data/${item.id}/instructions`"
                        target="_blank"
                        class="btn btn-xs btn-info"
                        @click.stop
                    >
                        Instructions
                    </a>
                </template>
                <template #[`items.instructions_form`]="{ item }">
                    <button
                        class="btn btn-xs btn-success"
                        @click.stop="openInstructionWindow(item)"
                    >
                        {{ item.instruction ? 'Edit' : 'Create' }}
                    </button>
                </template>
                <template #[`items.ort_file_link`]="{ item }">
                    <a
                        v-if="item.ort_file_link"
                        :href="item.ort_file_link"
                        target="_blank"
                        class="btn btn-xs btn-blue"
                        @click.stop
                    >
                        ORT
                    </a>
                    <a
                        v-if="item.ort_file_link_2"
                        :href="item.ort_file_link_2"
                        target="_blank"
                        class="btn btn-xs btn-blue ml-0 ml-1"
                        @click.stop
                    >
                        ORT 2
                    </a>
                </template>
                <template #[`items.print_sticker`]="{ item }">
                    <button
                        class="btn btn-xs btn-info"
                        @click.stop="dymoPrintLabel(item, 'order', false)"
                    >
                        Order
                    </button>
                    <button
                        class="btn btn-xs btn-info ml-1"
                        @click.stop="dymoPrintLabel(item, 'fda')"
                    >
                        FDA
                    </button>
                    <button
                        class="btn btn-xs btn-blue ml-1"
                        @click.stop="dymoPrintLabel(item, 'both', false)"
                    >
                        Both
                    </button>
                </template>
                <template #[`items.gender`]="{ item }">
                    <span v-if="item.account.genderIdentifier === 'gender_men'">
                        Man
                    </span>
                    <span v-else-if="item.account.genderIdentifier === 'gender_women'">
                        Woman
                    </span>
                </template>
                <template #[`items.fedex_links`]="{ item }">
                    <button
                        v-if="item.fedex_label"
                        class="btn btn-xs btn-info"
                        @click.stop="getFedexDocument(item.fedex_label)"
                    >
                        Label
                    </button>
                    <button
                        v-if="item.fedex_invoice"
                        class="btn btn-xs btn-info ml-1"
                        @click.stop="getFedexDocument(item.fedex_invoice)"
                    >
                        Invoice
                    </button>
                </template>
                <template #[`items.warehouse`]="{ value }">
                    {{ value.name }}
                </template>
                <template #[`items.fedex_tracking`]="{ item }">
                    <span v-for="(trackingNumber, index) in item.fedex_tracking_numbers" :key="index">
                        {{ trackingNumber }}
                        <a
                            class="action-icon"
                            @click.stop="copyToClipboard(trackingNumber)"
                        >
                            <i class="far fa-copy" />
                        </a>
                        <br />
                    </span>
                </template>
                <template #[`items.images`]="{ value }">
                    {{ value.length }}
                </template>
            </awesome-table>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import config from '@/config';
import appConfig from '@src/app.config';
import dymoPrinterMixin from '@src/mixins/dymo-printer.mixin.js';

export default {
    page: {
        title: 'Factory Data',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    mixins: [dymoPrinterMixin],

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'order.created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Factory Data',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Factory Data',
                    active: true
                }
            ],
            labels: [
                {
                    text: 'Order Number',
                    value: 'order.order_number'
                },
                {
                    text: 'Order Date',
                    value: 'order.created_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'assignee',
                    type: 'enum',
                    enumOptions: []
                },
                {
                    value: 'priority',
                    type: 'enum',
                    enumOptions: [
                        {
                            text: 'Normal',
                            value: 'NORMAL'
                        },
                        {
                            text: 'High',
                            value: 'HIGH'
                        },
                        {
                            text: 'Very High',
                            value: 'VERY_HIGH'
                        },
                        {
                            text: 'Expedited Done',
                            value: 'EXPEDITED_DONE'
                        }
                    ]
                },
                'quantity',
                {
                    value: 'status_date',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'status',
                    type: 'enum',
                    enumOptions: [
                        'ORDER RECEIVED',
                        'SENT IMPRESSION KIT',
                        'SCANNED & READY FOR DESIGN',
                        'DESIGNED & READY FOR PRODUCTION',
                        'PRODUCTION PROCESS WITH SHIPMENT',
                        'SHIP TO CUSTOMER',
                        `DESIGN - WAITING ON CUSTOMER'S REPLY`,
                        'ENTERING PRODUCTION LINE',
                        'HOLD',
                        'ADDRESS VERIFICATION',
                        'RTS',
                        'OLD ORDER',
                        `REM - WAITING FOR CUSTOMER'S REPLY`,
                        'REM - DESIGNED & READY FOR PRODUCTION',
                        'REM - PRODUCTION',
                        'REM - SHIP TO CUSTOMER',
                        'REQUEST FOR CANCELATION PRE SHIP',
                        'CANCELED PRE SHIP',
                        'REQUEST FOR CANCELATION AFTER SHIP',
                        'CANCELED',
                        'ITEM HAS BEEN RETURNED'
                    ]
                },
                {
                    text: 'Customer Name',
                    value: 'order.customer.first_name',
                    sortable: false
                },
                {
                    text: 'Account Name',
                    value: 'account.name',
                    sortable: false
                },
                {
                    text: 'Customer Email',
                    value: 'order.customer.email',
                    sortable: false
                },
                {
                    text: 'Shell Material',
                    value: 'instruction.shell_material',
                    sortable: false,
                    filterable: false
                },
                {
                    text: '3D Scan',
                    value: 'scan_link',
                    sortable: false,
                    filterable: false,
                    width: '120px'
                },
                {
                    text: '3D Scan Date',
                    value: 'scan_date',
                    filter: 'formatDateOnly',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'instructions_file',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'instructions_form',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Design Date',
                    value: 'instruction.created_at',
                    filter: 'formatDateOnly',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'ORT File',
                    value: 'ort_file_link',
                    sortable: false,
                    filterable: false,
                    width: '140px'
                },
                {
                    text: 'First Order Scan Date',
                    value: 'first_scan',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'shipping_tracking',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'print_sticker',
                    sortable: false,
                    filterable: false,
                    width: '190px'
                },
                {
                    value: 'gender',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Product Name',
                    value: 'productColor.product.name',
                    sortable: false,
                    filterable: false
                },
                {
                    text: 'Shoe Size',
                    value: 'size'
                },
                {
                    value: 'fedex_links',
                    sortable: false,
                    filterable: false,
                    width: '150px'
                },
                {
                    value: 'warehouse',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'fedex_tracking',
                    sortable: false,
                    filterable: false,
                    width: '150px'
                },
                {
                    value: 'images',
                    sortable: false,
                    filterable: false
                }
            ],
            orderProductColors: [],
            users: [],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            selected: [],
            isLoading: false,
            onlyConsistentStatus: false,
            fedexData: {
                shipments: [],
                shipmentsTotal: 0,
                errors: []
            },
            shipmentInProgress: false,
            batchInProgress: false,
            appUrl: config.url,
            customerId: null
        };
    },

    computed: {
        ordersList() {
            const orderNumbers = this.orderProductColors.map(
                orderProductColor => orderProductColor.order.order_number
            );

            const uniqueOrderNumbers = [...new Set(orderNumbers)];

            return uniqueOrderNumbers.map((order, index) => ({
                orderNumber: order,
                rowClass: index % 2 ? 'row_dark' : ''
            }));
        }
    },

    async created() {
        this.customerId = this.$route.params.customerId;

        if (this.customerId) {
            this.fetchCustomer();
        }

        this.fetchOrderProductColors();
        this.fetchUsers();
    },

    beforeDestroy() {
        window.removeEventListener('focus', this.handleFocusChange, false);
    },

    methods: {
        ...mapActions({
            getOrderProductColors: 'orderProductColors/index',
            createShipment: 'orderProductColors/createShipment',
            createBatch: 'batches/store',
            getCustomer: 'customers/show',
            getUsers: 'users/index'
        }),

        async fetchOrderProductColors() {
            this.isLoading = true;

            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters,
                    onlyConsistentStatus: this.onlyConsistentStatus
                };

                if (this.customerId) {
                    options.customerId = this.customerId;
                }

                const { rows, count } = await this.getOrderProductColors(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.orderProductColors = rows;
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }

            this.isLoading = false;
        },

        async fetchUsers() {
            const { rows } = await this.getUsers({
                perPage: 100
            });

            this.users = rows;

            this.assigUsersOptions();
        },

        async fetchCustomer() {
            try {
                const customer = await this.getCustomer(this.customerId);

                this.title = `Factory Data of ${customer.first_name} ${
                    customer.last_name
                }`;

                const customersHref = {
                    text: 'Customers',
                    to: '/customers'
                };

                this.items = [this.items[0], customersHref, this.items[1]];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        assigUsersOptions() {
            const designers = this.users.filter(
                user =>
                    user.acl &&
                    (user.acl.permissions.all || user.acl.permissions.designer)
            );

            const sortedDesigners = designers.sort((a, b) =>
                a.first_name.localeCompare(b.first_name)
            );

            const options = sortedDesigners.map(user => ({
                text: `${user.first_name} ${user.last_name}`,
                value: user.id
            }));

            this.labels[2].enumOptions = [...options];
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchOrderProductColors();
        },

        onSelectionChange(items) {
            this.selected = [...items];
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchOrderProductColors();
        },

        onConsistentStatusChange() {
            this.onlyConsistentStatus = !this.onlyConsistentStatus;
            this.pagination.currentPage = 1;

            this.fetchOrderProductColors();
        },

        getRowClass({ order }) {
            if (!order) {
                return '';
            }

            const t = this.ordersList.find(
                orderClass => orderClass.orderNumber === order.order_number
            );

            return t.rowClass;
        },

        async printInstructions() {
            const ids = [...this.selected];

            const queryIds = ids.join(',');

            this.$router.push({
                path: 'factory-data/group-instructions',
                query: {
                    ids: queryIds
                }
            });
        },

        async onCreateShipment() {
            const confirmed = await this.$confirmationModal(
                'This action will create FedEx shipments.',
                { title: 'Are you sure?' }
            );

            if (!confirmed) {
                return;
            }

            this.shipmentInProgress = true;

            this.fedexData = {
                shipments: [],
                shipmentsTotal: 0,
                errors: []
            };

            try {
                this.fedexData = await this.createShipment(this.selected);

                this.fetchOrderProductColors();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.shipmentInProgress = false;
        },

        async onCreateBatch() {
            const confirmed = await this.$confirmationModal(
                'This action will create FedEx shipments and change selected products statuses.',
                { title: 'Are you sure?' }
            );

            if (!confirmed) {
                return;
            }

            this.batchInProgress = true;

            this.fedexData = {
                shipments: [],
                shipmentsTotal: 0,
                errors: []
            };

            try {
                this.fedexData = await this.createBatch(this.selected);

                this.fetchOrderProductColors();
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }

            this.batchInProgress = false;
        },

        getBadgeClass(value) {
            let color = 'success';

            if (value === 'HIGH') {
                color = 'warning';
            } else if (value === 'VERY_HIGH') {
                color = 'orange';
            } else if (value === 'EXPEDITED_DONE') {
                color = 'danger';
            }

            return `badge badge-${color}`;
        },

        openInstructionWindow(item) {
            window.addEventListener('focus', this.handleFocusChange, false);

            window.open(
                `${this.appUrl}/factory-data/${item.id}/instructions/edit`,
                '_blank',
                'location=yes, height=800, width=520, scrollbar=yes, status=yes'
            );
        },

        handleFocusChange() {
            this.fetchOrderProductColors();

            window.removeEventListener('focus', this.handleFocusChange, false);
        },

        getFedexDocument(fileName) {
            const url = `${config.apiUrl}/fedex_documents/${fileName}`;

            const fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.setAttribute(
                'download',
                `${fileName}.pdf`
            );
            fileLink.setAttribute('target', `_blank`);
            document.body.appendChild(fileLink);
            fileLink.click();
        },

        copyTrackingNumbersToClipboard() {
            const orderProductColors = this.orderProductColors.filter(
                orderProductColor =>
                    this.selected.includes(orderProductColor.id)
            );

            const trackingNumbers = orderProductColors.flatMap(
                selected => selected.fedex_tracking_numbers
            );

            const text = trackingNumbers.join(', ');

            return this.copyToClipboard(text);
        },

        async copyToClipboard(text) {
            await navigator.clipboard.writeText(text);

            this.$toaster('Copied to clipboard!');
        }
    }
};
</script>

<style lang="scss">
.row_dark {
    background-color: #e2e8ea !important;
}

.vs__dropdown-menu {
    min-width: 250px;
}

.table-alert {
    .table {
        max-width: 1200px;
        color: #7d2c38;
    }

    .table th, .table td {
        border-top: 1px solid #fbcfd6;
    }

    .table thead th {
        border-bottom: 2px solid #fbcfd6;
    }
}
</style>
