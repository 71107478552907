var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"mb-2 text-right"},[_c('button',{staticClass:"btn waves-effect waves-light",class:("btn-" + (_vm.onlyConsistentStatus ? 'success' : 'danger')),on:{"click":_vm.onConsistentStatusChange}},[_vm._v("\n                Consistent Statuses "+_vm._s(_vm.onlyConsistentStatus ? 'Enabled' : 'Disabled')+"\n                "),_c('span',{staticClass:"btn-label-right"},[(_vm.onlyConsistentStatus)?_c('i',{staticClass:"mdi mdi-check-all"}):_c('i',{staticClass:"mdi mdi-cancel"})])])]),_c('b-alert',{attrs:{"show":!!_vm.fedexData.shipmentsTotal,"dismissible":"","variant":"success"},on:{"dismissed":function($event){_vm.fedexData.shipmentsTotal = 0}}},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.fedexData.shipments.length)+" of "+_vm._s(_vm.fedexData.shipmentsTotal))]),_vm._v("\n                FedEx shipments were successfully created!\n            ")])]),_c('b-alert',{attrs:{"show":!!_vm.fedexData.errors.length,"dismissible":"","variant":"danger"},on:{"dismissed":function($event){_vm.fedexData.errors = []}}},[_c('span',[_vm._v("Errors occured during creation of FedEx shipments:")]),_c('div',{staticClass:"table-responsive table-alert"},[_c('table',{staticClass:"table mt-3 table-centered table-sm"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Order Number")]),_c('th',[_vm._v("Code")]),_c('th',[_vm._v("Message")])])]),_c('tbody',[_vm._l((_vm.fedexData.errors),function(fedexError){return _vm._l((fedexError.errors),function(error,index){return _c('tr',{key:((fedexError.orderNumber) + "_" + index)},[_c('td',[_vm._v(_vm._s(fedexError.orderNumber))]),_c('td',[_vm._v(_vm._s(error.code))]),_c('td',[_vm._v(_vm._s(error.message))])])})})],2)])])]),_c('awesome-table',{key:_vm.users.length,attrs:{"id":"factory-data","labels":_vm.labels,"items":_vm.orderProductColors,"options":{
                addButton: false,
                sort: true,
                select: true,
                filters: true,
                columnsSelector: true,
                csvExport: false,
                isCard: false,
                tableStyle: 'font-size: 12px;'
            },"pagination":_vm.pagination,"is-loading":_vm.isLoading,"filters":_vm.filters,"get-row-class":_vm.getRowClass},on:{"row-click":function($event){return _vm.$router.push(("/factory-data/" + ($event.id)))},"mouse-wheel-click":function($event){return _vm.$openNewTab(("/factory-data/" + ($event.id)))},"pagination-change":_vm.onPaginationChange,"selection-change":_vm.onSelectionChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"selectButtons",fn:function(){return [_c('button',{staticClass:"btn btn-primary waves-effect waves-light mr-1",attrs:{"type":"button"},on:{"click":_vm.printInstructions}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"mdi mdi-printer"})]),_vm._v("\n                    Print Instructions\n                ")]),_c('button',{staticClass:"btn btn-blue waves-effect waves-light mr-1",attrs:{"type":"button","disabled":_vm.shipmentInProgress || _vm.batchInProgress},on:{"click":_vm.onCreateShipment}},[_c('span',{staticClass:"btn-label"},[(_vm.shipmentInProgress)?_c('b-spinner',{attrs:{"small":""}}):_c('i',{staticClass:"fab fa-fedex"})],1),_vm._v("\n                    Create Shipment\n                ")]),_c('button',{staticClass:"btn btn-blue waves-effect waves-light mr-1",attrs:{"type":"button","disabled":_vm.shipmentInProgress || _vm.batchInProgress},on:{"click":_vm.onCreateBatch}},[_c('span',{staticClass:"btn-label"},[(_vm.batchInProgress)?_c('b-spinner',{attrs:{"small":""}}):_c('i',{staticClass:"mdi mdi-alpha-b-box"})],1),_vm._v("\n                    Create Batch\n                ")]),_c('button',{staticClass:"btn btn-info waves-effect waves-light mr-1",attrs:{"type":"button"},on:{"click":_vm.copyTrackingNumbersToClipboard}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"far fa-copy"})]),_vm._v("\n                    Copy Tracking\n                ")])]},proxy:true},{key:"items.order.order_number",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.order.order_number || item.order.magento_order_number)+"\n            ")]}},{key:"items.assignee",fn:function(ref){
            var item = ref.item;
return [(item.assignee)?_c('span',[_vm._v("\n                    "+_vm._s(((item.assignee.first_name) + "\n                        " + (item.assignee.last_name)))+"\n                ")]):_vm._e()]}},{key:"items.priority",fn:function(ref){
                        var value = ref.value;
return [_c('span',{class:_vm.getBadgeClass(value)},[_vm._v("\n                    "+_vm._s(_vm._f("startCase")(value))+"\n                ")])]}},{key:"items.order.customer.first_name",fn:function(ref){
                        var item = ref.item;
return [_vm._v("\n                "+_vm._s(((item.order.customer.first_name) + "\n                    " + (item.order.customer.last_name)))+"\n            ")]}},{key:"items.account.name",fn:function(ref){
                    var item = ref.item;
return [(item.account.name)?_c('span',[_vm._v("\n                    "+_vm._s(item.account.name)+"\n                ")]):_c('span',[_vm._v("\n                    "+_vm._s(((item.order.customer.first_name) + "\n                        " + (item.order.customer.last_name)))+"\n                ")])]}},{key:"items.scan_link",fn:function(ref){
                        var item = ref.item;
return [(item.scan_link)?_c('a',{staticClass:"btn btn-xs btn-blue",attrs:{"href":item.scan_link,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    3D Scan\n                ")]):_vm._e()]}},{key:"items.instructions_file",fn:function(ref){
                        var item = ref.item;
return [(item.instruction)?_c('a',{staticClass:"btn btn-xs btn-info",attrs:{"href":(_vm.appUrl + "/factory-data/" + (item.id) + "/instructions"),"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    Instructions\n                ")]):_vm._e()]}},{key:"items.instructions_form",fn:function(ref){
                        var item = ref.item;
return [_c('button',{staticClass:"btn btn-xs btn-success",on:{"click":function($event){$event.stopPropagation();return _vm.openInstructionWindow(item)}}},[_vm._v("\n                    "+_vm._s(item.instruction ? 'Edit' : 'Create')+"\n                ")])]}},{key:"items.ort_file_link",fn:function(ref){
                        var item = ref.item;
return [(item.ort_file_link)?_c('a',{staticClass:"btn btn-xs btn-blue",attrs:{"href":item.ort_file_link,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    ORT\n                ")]):_vm._e(),(item.ort_file_link_2)?_c('a',{staticClass:"btn btn-xs btn-blue ml-0 ml-1",attrs:{"href":item.ort_file_link_2,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("\n                    ORT 2\n                ")]):_vm._e()]}},{key:"items.print_sticker",fn:function(ref){
                        var item = ref.item;
return [_c('button',{staticClass:"btn btn-xs btn-info",on:{"click":function($event){$event.stopPropagation();return _vm.dymoPrintLabel(item, 'order', false)}}},[_vm._v("\n                    Order\n                ")]),_c('button',{staticClass:"btn btn-xs btn-info ml-1",on:{"click":function($event){$event.stopPropagation();return _vm.dymoPrintLabel(item, 'fda')}}},[_vm._v("\n                    FDA\n                ")]),_c('button',{staticClass:"btn btn-xs btn-blue ml-1",on:{"click":function($event){$event.stopPropagation();return _vm.dymoPrintLabel(item, 'both', false)}}},[_vm._v("\n                    Both\n                ")])]}},{key:"items.gender",fn:function(ref){
                        var item = ref.item;
return [(item.account.genderIdentifier === 'gender_men')?_c('span',[_vm._v("\n                    Man\n                ")]):(item.account.genderIdentifier === 'gender_women')?_c('span',[_vm._v("\n                    Woman\n                ")]):_vm._e()]}},{key:"items.fedex_links",fn:function(ref){
                        var item = ref.item;
return [(item.fedex_label)?_c('button',{staticClass:"btn btn-xs btn-info",on:{"click":function($event){$event.stopPropagation();return _vm.getFedexDocument(item.fedex_label)}}},[_vm._v("\n                    Label\n                ")]):_vm._e(),(item.fedex_invoice)?_c('button',{staticClass:"btn btn-xs btn-info ml-1",on:{"click":function($event){$event.stopPropagation();return _vm.getFedexDocument(item.fedex_invoice)}}},[_vm._v("\n                    Invoice\n                ")]):_vm._e()]}},{key:"items.warehouse",fn:function(ref){
                        var value = ref.value;
return [_vm._v("\n                "+_vm._s(value.name)+"\n            ")]}},{key:"items.fedex_tracking",fn:function(ref){
                        var item = ref.item;
return _vm._l((item.fedex_tracking_numbers),function(trackingNumber,index){return _c('span',{key:index},[_vm._v("\n                    "+_vm._s(trackingNumber)+"\n                    "),_c('a',{staticClass:"action-icon",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(trackingNumber)}}},[_c('i',{staticClass:"far fa-copy"})]),_c('br')])})}},{key:"items.images",fn:function(ref){
                        var value = ref.value;
return [_vm._v("\n                "+_vm._s(value.length)+"\n            ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }